$this: #1f5b30;
$that: #1e98c9;
$tertiary: #c4c4c4;
$background: #f5d1d1;
$text: #2e2e2e;
$white: #eeeeee;
$bank: #abe7b9;
$cms: #2CA5ED;
$eyescan: #EFD385;

body {
  background: #fff;
  overscroll-behavior-y: none;
}

h1,
h2,
h3,
h4,
p,
a,
li,
ul {
  color: $text;
  margin-top: 0;
  line-height: 100%;
  font-style: normal;
  font-weight: normal;
  font-family: Numans;
}

ul {
  @media (max-width: 768px) {
    padding-left: 20px;
  }
}

li {

  &.bank {
    &::marker {
      color: $bank;
    }
  }
  &.eyescan {
    &::marker {
      color: $eyescan;
    }
  }
  &.cms {
    &::marker {
      color: $cms;
    }
  }
}


button {
  color: $text;
  font-size: 2em;
  @media only screen and (max-width: 425px) {
    font-size: 1.6em;
  }
  margin: 0;
  line-height: 100%;
  font-style: normal;
  font-weight: normal;
  font-family: Numans;

  &:hover {
    background: darken($background, 5%);
  }
}

h1 {
  color: $text;
  @media (max-width: 425px) {
    font-size: 1.9em;
  }


  @media (min-width: 768px) {
    font-size: 2em;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 3em;
  }
}

h2 {
  font-size: 2em;
  @media only screen and (max-width: 425px) {
    font-size: 1.6em;
  }
}

h3 {
  font-size: 2em;
  @media only screen and (max-width: 425px) {
    font-size: 1.6em;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 2em;
  }


}
h4 {
  font-size: 1.5em;
  @media only screen and (max-width: 425px) {
    font-size: 1.3em;
  }
}

li,
p {
  font-size: 1.5em;
  @media only screen and (max-width: 425px) {
    font-size: 1.4em;
    line-height: 1em;
  }
  line-height: 24px;
  font-family: Raleway;
}

a {
  font-size: 1em;
  @media only screen and (max-width: 768px) {
    // font-size: 0.83em;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 1.2em;
  }
  font-family: Raleway;
  cursor: pointer;
  text-decoration: none;

  &.nav {
    margin-left: 60px;
    color: $text;

    @media (max-width: 425px) {
      margin-left: 30px;
      font-size: 1.5em;
    }

    &:hover {
      background-color: darken($background, 5%);
      transition: all 0.5s;
    }

    &:visited {
      color: $text;
    }
  }

  &:focus {
    outline: none;
  }

  &:visited {
    color: $text;
  }

  button {
    font-size: 1.5em;

    @media (max-width: 425px) {
      font-size: 1em;
    }
  }

  & h1,h2,h3,h4 {
    font-size: 1.5em;
    margin: 0;
  }
}

img {
  height: auto;
  width: auto;
  display: block;
}

input,
textarea {
  margin-left: 6px;
  background: none;
  border: none;
  border-bottom: $background solid 1px;
  width: 326px;
  max-width: 326px;
  min-width: 326px;
  max-height: 250px;
  color: $background;
  font-family: Raleway;
  font-size: 1.5em;

  &:focus {
    outline: none;
  }
}

#soundcloud {
  font-size: 1.1em;
  @media only screen and (max-width: 425px) {
    font-size: 1.1em;
    line-height: 12px;
  }
  line-height: 24px;
  display: inline;
  font-family: Raleway;

  &:visited {
    color: $background;
  }

  &:hover {
    background-color: #c4c4c4;
    background-blend-mode: difference;
  }
}

.footer {
  color: $text;
  text-align: center;
}

.work-hero {
  max-height: 40vh;
  width: 100vw;
  object-fit: cover;
  margin-bottom: 80px;

  @media (max-width: 768px) {
    margin-bottom: 0;
  }
}

.work-container {
  position: relative;
  top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 160px;
  box-sizing: border-box;
  width: 100%;
  max-width: 630px;
  margin: 0 auto;

  /* & > * {
    margin-bottom: 60px;
} */

  @media (max-width: 768px) {
    padding: 0 20px;
    padding-bottom: 20px;
  }

  &.workheading {
    align-items: flex-start;
  }
}

.workheading {
  border-bottom: 5px solid;
  margin-bottom: 12px;
  align-self: flex-start;

  &.title {
    align-self: center;
    text-align: center;
  }

  &.eyescan {
    border-bottom-color: $eyescan;
  }

  &.cms {
    border-bottom-color: $cms;
  }

  &.bank {
    border-bottom-color: $bank;
  }
}

.work-subtitle {
  margin-bottom: 30px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.work-section {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
}

.role-team {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 60px;

  @media (max-width: 768px) {
   flex-direction: column;
   margin-bottom: 40px;
  }
}

.role-container {
  display: flex;
  flex-direction: column;
}

.team-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > .workheading {
    align-self: center;

    @media (max-width: 768px) {
      align-self: flex-start;
    }
  }
}

.teamflex {
  display: flex;
  flex-direction: row;

  @media (max-width: 425px) {
    flex-direction: column;

    ul {
      margin-block-end: 0;
    }
  }

}

.work-pics {
  z-index: -1;
  position: relative;
  top: 0;
  left: 0;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  right: 50%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 12px;
  box-sizing: border-box;
  margin-bottom: 60px;
  
  @media (max-width: 768px) {
    margin-bottom: 20px;
    padding: 20px;
  }

  // background: $eyescan;
  // color: $text;
  
  & > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    max-width: 100%;
    align-items: center;

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
      &.solo {
        display: flex;
      }
    }

    @media (max-width: 425px) {
      display: grid;
      grid-template-columns: 1fr;
      max-width: 80%;
      &.solo {
        display: flex;
      }
    }
  }


  img {
    margin-right: 16px;
    max-height: 160px;
    filter: drop-shadow(10px 10px 40px rgba(0, 0, 0, 0.25));
    max-width: 50vw;
    width: auto;
    height: auto;

    &.eyescan {
      filter: drop-shadow(10px 10px 40px rgba(255, 255, 255, 0.25));
    }

    &.final-pic {
      max-height: 30vh;
      max-width: 50vw;
    }

    @media (max-width: 1024px) {
      max-width: 23vw;
    }
    @media (max-width: 768px) {
      max-width: 45vw;
    }
    @media (max-width: 425px) {
      margin-bottom: 16px;
      &.final-pic {
        max-height: 15vh;
      }
  
    }
  }

  &.bank {
    background: $bank;
    & p {
      color: $text;
    }
  }

  &.cms {
    background: $cms;
    p {
      color: $text;
    }
  }
  &.eyescan {
    background: $eyescan;
    p {
      color: $text;
    }
  }
}

.backlink {
  position: absolute;
  left: -60px;

  &.bottom {
    bottom: 60px;
  }

  &.top {
    top: -60px;
  }

  @media (max-width: 768px) {

    left: 20px;

    &.bottom {
      bottom: 20px;
    }
  
    &.top {
      top: -20px;
    }
  }

  @media (max-width: 425px) {


    &.bottom {
      bottom: 20px;
    }
  
    &.top {
      top: -30px;
    }
  }

  &::after {
    content: "back";
    color: $background;
    background: $background;
    position: absolute;
    left: -10px;
    bottom: -10px;
    z-index: -1;
    transition: all 0.2s;
  }

  &:hover {

    &::after {
    left: 0;
    bottom: 0;
    }
  }
}

.to-end {
  align-self: flex-start;
}

.skip {
  position: relative;
  margin-bottom: 60px;

  @media (max-width: 768px) {

  }

  &.bank {
    &::after {
      color: $bank;
      background: $bank;
    }
  }
  &.eyescan {
    &::after {
      color: $eyescan;
      background: $eyescan;
    }
  }
  &.cms {
    &::after {
      color: $cms;
      background: $cms;
    }
  }

  &::after {
    content: "skip to end";

    position: absolute;
    left: -10px;
    bottom: -10px;
    z-index: -1;
    transition: all 0.2s;
  }

  &:hover {

    &::after {
    left: 0;
    bottom: 0;
    }
  }
}

.anchor {
  position: relative;
  top: -48px;
}

#podcast-caption {
  margin-top: -40px;
  margin-bottom: 40px;
}

#eyescanvid {
  height: auto;
  width: 95%;
  margin: 12px auto;
  max-width: 1000px;
}

iframe, #podcast-caption {
  @media only screen and (max-width: 525px) {
    display: none;
  }
}

#quote {
  width: 55vw;
  @media only screen and (max-width: 425px) {
    width: 100%;
  }
}